<template>
  <div class="B1e-item">
    <el-container>
      <el-header style="padding: 0" height="100%">
        <vheadere class="hidden-sm-and-down"></vheadere>
        <ivheaderae class="hidden-md-and-up"></ivheaderae>
        <div class="top-bg">
          <div class="top-content hidden-xs-only">
            <el-row type="flex" style="justify-content: center;">
              <el-col :xs="24" :sm="24" :md="16">
                <div class="adv-map">
                  <div>
                    <img width="100%" height="100%" :src="require(cirSel == 1 ? '../../assets/images/B/B1/content.png' : (cirSel == 2? '../../assets/images/B/B1/content2.png':'../../assets/images/B/B1/content3.png'))" alt="" />
                    <div class="map-icon">
                      <transition name="mapIcon1-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map8.png" alt="" /></transition>
                      <transition name="mapIcon2-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map7.png" alt="" /></transition>
                      <transition name="mapIcon3-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map6.png" alt="" /></transition>
                      <transition name="mapIcon4-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map5.png" alt="" /></transition>
                      <transition name="mapIcon5-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map4.png" alt="" /></transition>
                      <transition name="mapIcon6-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map3.png" alt="" /></transition>
                      <transition name="mapIcon7-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map2.png" alt="" /></transition>
                      <transition name="mapIcon8-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map1.png" alt="" /></transition>
                      <transition name="mapIcon9-fade"><img v-show="mapIcon && cirSel === 1" src="../../assets/images/B/B1/map9.png" alt="" /></transition>
                    </div>
                    <div class="map-icon m2">
                      <transition name="mapIcon1-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon21.png" alt="" /></transition>
                      <transition name="mapIcon2-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon22.png" alt="" /></transition>
                      <transition name="mapIcon3-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon23.png" alt="" /></transition>
                      <transition name="mapIcon4-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon24.png" alt="" /></transition>
                      <transition name="mapIcon5-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon25.png" alt="" /></transition>
                      <transition name="mapIcon6-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon26.png" alt="" /></transition>
                      <transition name="mapIcon7-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon27.png" alt="" /></transition>
                      <transition name="mapIcon8-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon28.png" alt="" /></transition>
                      <transition name="mapIcon9-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon29.png" alt="" /></transition>
                      <transition name="mapIcon10-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon210.png" alt="" /></transition>
                      <transition name="mapIcon11-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon211.png" alt="" /></transition>
                      <transition name="mapIcon12-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon212.png" alt="" /></transition>
                      <transition name="mapIcon13-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon213.png" alt="" /></transition>
                      <transition name="mapIcon14-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon214.png" alt="" /></transition>
                      <transition name="mapIcon15-fade2"><img v-show="mapIcon && cirSel === 2" src="../../assets/images/HOME/adv-mapIcon215.png" alt="" /></transition>
                    </div>
                    <div class="map-icon m3">
                      <transition name="mapIcon1-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon1.png" alt="" /></transition>
                      <transition name="mapIcon2-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon2.png" alt="" /></transition>
                      <transition name="mapIcon3-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon3.png" alt="" /></transition>
                      <transition name="mapIcon4-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon4.png" alt="" /></transition>
                      <transition name="mapIcon5-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon5.png" alt="" /></transition>
                      <transition name="mapIcon6-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon6.png" alt="" /></transition>
                      <transition name="mapIcon7-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon7.png" alt="" /></transition>
                      <transition name="mapIcon8-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon8.png" alt="" /></transition>
                      <transition name="mapIcon9-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon9.png" alt="" /></transition>
                      <transition name="mapIcon10-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon10.png" alt="" /></transition>
                      <transition name="mapIcon11-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon11.png" alt="" /></transition>
                      <transition name="mapIcon12-fade3"><img v-show="mapIcon && cirSel === 3" src="../../assets/images/B/B1/map3icon12.png" alt="" /></transition>
                    </div>
                    <div class="cnts">
                      <p class="map-tit1">{{ maptit[cirSel - 1].TITLE }}</p>
                      <p class="map-tit2">{{ maptit[cirSel - 1].SHORTCONTENT }}</p>
                      <div class="btns">
                        <a class="adv-moreBtn" :href="maptit[cirSel - 1 ].URL">Download Toolkits</a>
                        <a class="adv-dwBtn" :href="maptit[cirSel - 1].URL2">Introduction to All Sites</a>
                      </div>
                    </div>
                  </div>
                  <div class="cirbg"></div>
                  <span @click="cirBtn(1)" class="circle" :class="cirSel === 1 ? 'cir-active' : ''" style="left: 24%"></span>
                  <span @click="cirBtn(2)" class="circle" :class="cirSel === 2 ? 'cir-active' : ''" style="left: 49%"></span>
                  <span @click="cirBtn(3)" class="circle" :class="cirSel === 3 ? 'cir-active' : ''" style="left: 74%"></span>
                  <span class="map-sign" :style="'left:' + signLeft"></span>
                  <div class="mapnames">
                    <p class="mapname">WHOLE-CITY DISCOVERY <br />ROUTE</p>
                    <p class="mapname">CULTURE & CREATIVE <br />ROUTE</p>
                    <p class="mapname">ECO-DISCOVERY <br />ROUTE</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="top-content cm hidden-sm-and-up">
            <el-row type="flex" style="justify-content: center;">
              <el-col :xs="24" :sm="24" :md="16">
                <div class="adv-map">
                  <div>
                    <img width="100%" height="100%" :src="require(cirSel == 1 ? '../../assets/images/B/B1/content-m.png' : (cirSel == 2 ? '../../assets/images/B/B1/content2-m.png' : '../../assets/images/B/B1/content3-m.png'))" alt="" />
                    <div class="cnts">
                      <p class="map-tit1">{{ maptit[cirSel - 1].TITLE }}</p>
                      <div class="fra">
                        <p class="map-tit2">{{ maptit[cirSel - 1].SHORTCONTENT }}</p>
                        <div class="btns">
                          <a class="adv-moreBtn" :href="maptit[cirSel - 1].URL">Download Toolkits</a>
                          <a class="adv-dwBtn" :href="maptit[ cirSel - 1 ].URL2">Introduction to All Sites</a>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="map-icon">
                      <transition name="mapIcon1-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon11.png" alt="">
                      </transition>
                      <transition name="mapIcon2-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon12.png" alt="">
                      </transition>
                      <transition name="mapIcon3-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon13.png" alt="">
                      </transition>
                      <transition name="mapIcon4-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon14.png" alt="">
                      </transition>
                      <transition name="mapIcon5-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon15.png" alt="">
                      </transition>
                      <transition name="mapIcon6-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon16.png" alt="">
                      </transition>
                      <transition name="mapIcon7-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon17.png" alt="">
                      </transition>
                      <transition name="mapIcon8-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon18.png" alt="">
                      </transition>
                      <transition name="mapIcon9-fade">
                        <img v-show="mapIcon&&cirSel===1" src="../../assets/images/HOME/adv-mapIcon19.png" alt="">
                      </transition>
                    </div> -->
                  </div>
                  <div class="cirbg"></div>
                  <span @click="cirBtn(1)" class="circle" :class="cirSel === 1 ? 'cir-active' : ''" style="left: 24%"></span>
                  <span @click="cirBtn(2)" class="circle" :class="cirSel === 2 ? 'cir-active' : ''" style="left: 49%"></span>
                  <span @click="cirBtn(3)" class="circle" :class="cirSel === 3 ? 'cir-active' : ''" style="left: 74%"></span>
                  <span class="map-sign" :style="'left:' + signLeft"></span>
                  <div class="mapnames">
                      <p class="mapname">WHOLE-CITY DISCOVERY ROUTE</p>
                    <p class="mapname">CULTURE & CREATIVE ROUTE</p>
                      <p class="mapname">ECO-DISCOVERY ROUTE</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-header>
      <div class="tit t-one"></div>
      <div class="main-about">
        <div class="about">
          <div class="main-about-left">
            <img src="../../assets/images/B/B1/icon1.png" class="icon1" />
            <p class="left-p-one">{{ topcnt[0].TITLE }}</p>
            <div class="main-swiper">
              <div class="btns">
              </div>
              <div class="swiper" v-html="topcnt[0].CONTENT"><video :src="topcnt[0].IMAGE" autoplay="autoplay" loop="loop" controls="controls"></video></div>
            </div>
            <img src="../../assets/images/B/B1/icon3.png" class="icon1" />
            <p class="left-p-one">{{ topcnt[1].TITLE }}</p>
            <p class="left-p-two" v-html="topcnt[1].CONTENT"></p>
          </div>
          <div class="main-about-right">
            <div class="main-p">
              <img src="../../assets/images/B/B1/icon2.png" class="icon1" />
              <p class="left-p-one">{{ topcnt[2].TITLE }}</p>
              <p class="left-p-two" v-html="topcnt[2].CONTENT"></p>
            </div>
            <div class="main-p">
              <img src="../../assets/images/B/B1/icon4.png" class="icon1" />
              <p class="left-p-one">{{ topcnt[3].TITLE }}</p>
              <p class="left-p-two" v-html="topcnt[3].CONTENT"></p>
            </div>
          </div>
        </div>
        <router-link to="/WholeCityEN">
          <div class="link">
          </div>
        </router-link>
      </div>
      <div class="main-mid-card">
        <div id="card">
          <transition name="slide">
            <el-row class="cardrow" v-show="cardIcon">
              <el-col :xs="12" :sm="12" :md="7">
                <div class="card-cnt">
                  <p class="mid-card">Transporation</p>
                  <ul class="bom-icons-cnt">
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon1.png" class="card-icon" />
                      <p class="card-p-cnt">Metro</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon2.png" class="card-icon" />
                      <p class="card-p-cnt">Sharing Bike</p>
                    </li>
                    <li :xs="8" :sm="8" :md="8">
                      <img src="../../assets/images/B/B1/main-mid-icon3.png" class="card-icon" />
                      <p class="card-p-cnt">On Foot</p>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :xs="12" :sm="12" :md="7">
                <div class="card-cnt">
                  <p class="mid-card">Time consuming</p>
                  <ul class="bom-icons-cnt">
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon4.png" class="card-icon" />
                      <p class="card-p-cnt">Each site takes 30 mins to 1 hour</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon5.png" class="card-icon" />
                      <p class="card-p-cnt">Traffic between nearby sites takes 15 to 30 minutes</p>
                    </li>
                  </ul>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="10">
                <div class="card-cnt">
                  <p class="mid-card">Attention</p>
                  <ul class="bom-icons-cnt">
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon6.png" class="card-icon" />
                      <p class="card-p-cnt">Wear sportswear and prepare rain gear(if it rains)</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon7.png" class="card-icon" />
                      <p class="card-p-cnt">Fully charged your phone</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon8.png" class="card-icon" />
                      <p class="card-p-cnt">Keep traffic safty</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon9.png" class="card-icon" />
                      <p class="card-p-cnt">Protect the environment</p>
                    </li>
                    <li>
                      <img src="../../assets/images/B/B1/main-mid-icon10.png" class="card-icon" />
                      <p class="card-p-cnt">Payment for sharing bikes may be involved in the experience</p>
                    </li>
                  </ul>
                </div>
              </el-col>
            </el-row>
          </transition>
        </div>
        <div class="swiper-item">
          <el-row class="cardrow mid-row2">
            <el-col class="main-left" :xs="24" :sm="24" :md="12">
              <img src="../../assets/images/B/B1/tit-two.png" class="main-tit" />
              <img src="../../assets/images/B/B1/tit-two-m.png" class="main-tit-m" />
              <div class="main-left-swiper">
                <el-carousel trigger="click" arrow="always" :autoplay="false" indicator-position="none" :height="`${swiperh + 1}px`">
                  <el-carousel-item v-for="(item, index) in swiper1" :key="index">
                    <div class="swiper1">
                      <ul ref="swiper1ul">
                        <li v-for="(cnt, index) in item" :key="index">
                          <p class="swiper1-cnt">{{ cnt.SHORTCONTENT }}</p>
                          <div class="swiper1bom">
                            <div>
                              <img :src="cnt.IMAGE" class="swiper1tx" />
                            </div>
                            <div class="swiper1cnt2">
                              <p class="swiper1-name">{{ cnt.TITLE }}</p>
                              <p class="swiper1-date">{{ cnt.date }}</p>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
            <el-col class="main-right" :xs="24" :sm="24" :md="12">
              <img src="../../assets/images/B/B1/tit-three.png" class="main-tit" />
              <img src="../../assets/images/B/B1/tit-three-m.png" class="main-tit-m" />
              <div class="main-right-swiper">
                <el-carousel trigger="click" arrow="always" :autoplay="false" indicator-position="none" :height="`${swiperh2 + 1}px`">
                  <el-carousel-item v-for="(item, index) in swiper2" :key="index">
                    <div class="swiper2">
                      <ul ref="swiper2ul">
                        <li v-for="(cnt, index2) in item" :key="index2">
                          <div class="swipe2index">
                            <p>{{ (index*4)+(index2+1) > 9 ? (index*4)+(index2+1) : `0${(index*4)+(index2+1)}` }}</p>
                          </div>
                          <div class="swiper2cnt1">
                            <p class="swiper2-tit">{{ cnt.QUESTION }}</p>
                            <p class="swiper2-cnt">{{ cnt.ANSWER }}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="mian-mid2">
          <img src="../../assets/images/en/B1/t22.png" class="t-fonr" />
          <img src="../../assets/images/en/B1/t22.png" class="t-fonr-m" />
          <el-carousel trigger="hover" :height="screenWidth <= 990 ? '18rem' : '800px'">
            <el-carousel-item v-for="(arr, index) in showpic" :key="index">
              <el-row class="main-mid2-showpic">
                <!-- <a class="more-btn">查看全部</a> -->
                <el-col :xs="12" :sm="12" :md="8" v-for="(item, index1) in arr" :key="index1">
                  <img @click="popupBtn(item.TYPE, item.IMAGE)" v-if="item.TYPE == 2" :src="item.IMAGE" />
                  <img @click="popupBtn(item.TYPE, item.IMAGE)" v-else :src="item.COVER" />
                  <p :class="item.TYPE == 2? 'mid2-tit2':'mid2-tit'">{{ item.TITLE }}</p>
                  <p class="p-side">{{ item.ZHANDIAN }}</p>
                </el-col>
              </el-row>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-show="popupShow" class="popup-box" @click="closeBtn">
          <div class="play-box">
            <i @click="closeBtn" class="close-btn el-icon-error"></i>
            <video ref="video" controls v-if="popupType == 1" :src="this.popupSrc"></video>
            <img v-else :src="this.popupSrc" alt="">
          </div>
        </div>
        <div class="item-fun">
          <div class="main-funActive">
            <div class="buzz-funActive">
              <img src="../../assets/images/en/B1/t33.png" class="t-five" />
              <img src="../../assets/images/en/B1/t3m.png" class="t-five-m" />
              <div class="funActive-title">
                <img src="../../assets/images/HOME/title_funactive_en.png" alt="" class="title-funactive" />
                <div class="fun-btn display-none">
                  <router-link to="/createEN"><button>Create Events for Free</button></router-link>
                  <router-link to="/activitylistEN"><button>View All</button></router-link>
                </div>
              </div>
              <div class="funActive-card">
                <el-row>
                  <el-carousel trigger="hover" height="410px">
                    <el-carousel-item v-for="(arr, index) in recActivity" :key="index">
                      <h3 class="small" ref="funCur">
                        <el-col :xs="24" :sm="8" :md="8" v-for="(item, index1) in arr" :key="index1">
                          <div class="fcard-item" @click="activityBtn(item.MAKEACTIVITY_ID,item.USERID)">
                            <el-col :xs="12" :sm="24" :md="24">
                              <div class="over-hidden">
                                <img class="item-img"  width="100%" :src="item.ONE" alt="">
                              </div>
                            </el-col>
                            <el-col :xs="12" :sm="24" :md="24">
                              <div class="fcard-content">
                                <div class="fcard-txt">
                                  <p>{{item.TITLE}}</p>
                                </div>
                                <div class="fcard-icon">
                                  <p><i class="el-icon-time"></i><span>{{getTime(item.STARTTIME)}}</span> ~ <span>{{getTime(item.ENDTIME)}}</span></p>
                                  <p><i class="el-icon-location"></i>{{item.ADDRESS}}</p>
                                </div>
                                <div class="fcard-foot clearfloat display-none">
                                  <img :src="item.images" alt="">
                                  <span class="display-none">{{item.CNNAME}}</span>
                                </div>
                              </div>
                            </el-col>
                          </div>
                        </el-col>
                      </h3>
                    </el-carousel-item>
                  </el-carousel>
                </el-row>
              </div>
              <div class="fun-btn sm-up-noshow">
                <button @click="btn4phone">Create Events for Free</button>
                <router-link to="/activitylistEN"><button>View All</button></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-footer>
        <vfootere class="hidden-sm-and-down"></vfootere>
        <ivfootere class="hidden-md-and-up"></ivfootere>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { szcityb1, szcityb12, szcityb13, szcityb14, getResource } from '../../api-en'
import { q1Activity } from '../../api'

export default {
  data () {
    return {
      popupShow: false,
      popupSrc: '',
      popupType: '',
      maptit: [],
      recActivity: [],
      mapname: {
        name1: 'WHOLE-CITY DISCOVERY ROUTE',
        name2: 'CULTURE & CREATIVE ROUTE',
        name3: 'ECO-DISCOVERY ROUTE'
      },
      topcnt: [
        {
          name: "'Just Shenzhen' in 1 minute",
          cnt: ''
        },
        {
          name: 'Gamification',
          cnt:
            'The activity provides a route to explore the city and other special subjects (such as innovation, environmental protection, food). Referring to the directions, check in and interact at important landmarks, exploring Shenzhen in the form of a game. Therefore, participants can experience Shenzhen’s innovative vitality, and activities and fun will be added into city promotion.'
        },
        {
          name: 'Immersive Urban Exploration',
          cnt: 'The "Just Shenzhen" Urban Experience, is an "Internet plus" immersive public exploration activity with a unique Shenzhen perspective. It will help visitors attain an in-depth understanding of the culture, history, technology and living environment of Shenzhen.'
        },
        {
          name: 'For all ages',
          cnt: '“Just Shenzhen” is designed for people of all ages and backgrounds; whether you’re a newcomer in town or born-and-raised in Shenzhen, you can always discover something new and interesting!'
        }
      ],
      swiper1: [],
      swiper2: [],
      showpic: [],
      video: '',
      swiperh: 0,
      swiperh2: 0,
      signLeft: [],
      cirSel: 1,
      mapIcon: false,
      cardIcon: false,
      findex: 0,
      screenWidth: 0
    }
  },
  beforeDestroy () {
    window.removeEventListener('touchstart', this.touchstart)
    window.removeEventListener('touchmove', this.touchmove)
    window.removeEventListener('scroll', this.pageScroll)
  },
  mounted () {
    getResource().then(res => {
      let arr = res.data
      for (let i = 0; i < arr.length; i += 6) {
        this.showpic.push(arr.slice(i, i + 6))
      }
    })
    q1Activity().then((res) => {
      let result = []
      let result1 = []
      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i].RECOMMEND == 'true') {
          if (res.data[i].是否显示 == 'true') {
            result.push(res.data[i])
          }
        }
      }
      for (let i = 0; i < result.length; i += 3) {
        result1.push(result.slice(i, i + 3))
      }
      this.recActivity = result1
    })
    szcityb1().then(res => {
      this.maptit = res.data
    })
    szcityb12().then(res => {
      let div = $('<div/>')
      div.html(res.data[0].CONTENT)
      let video = div.find('video')
      video.attr('poster', res.data[0].IMAGE)
      this.topcnt = res.data
      this.topcnt[0].CONTENT = div.html()
    })
    szcityb13().then(res => {
      let list = []
      for (let i = 0; i < res.data.length; i += 3) {
        list.push(res.data.slice(i, i + 3))
      }
      this.swiper1 = list
      this.$nextTick(() => {
        setTimeout(() => {
          this.swiperh = $('.B1e-item .swiper1 ul').outerHeight(true)
        }, 200)
      })
    })
    szcityb14().then(res => {
      let list = []
      for (let i = 0; i < res.data.length; i += 4) {
        list.push(res.data.slice(i, i + 4))
      }
      this.swiper2 = list
      this.$nextTick(() => {
        setTimeout(() => {
          this.swiperh2 = $('.B1e-item .swiper2 ul').outerHeight(true)
        }, 200)
      })
    })
    this.$nextTick(() => {
      window.addEventListener('touchstart', this.touchstart)
      window.addEventListener('touchmove', this.touchmove, {
        passive: false
      })
      window.screenWidth = document.body.clientWidth
      this.screenWidth = window.screenWidth
    })
    window.addEventListener('scroll', this.pageScroll)
    this.mapIcon = true
  },
  methods: {
    btn4phone () {
      this.$message({
        type: 'info',
        message: 'Create an event on PC ',
        center: true
      })
    },
    popupBtn (type, src) {
      this.popupShow = true
      this.popupSrc = src
      this.popupType = type
    },
    closeBtn () {
      this.popupShow = false
      this.$refs.video.pause()
    },
    getTime (timeDate) {
      var date = new Date(timeDate.replace(/-/g, '/'))
      // var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      var currentTime = month + '-' + day + '- ' + hour + ':' + minute
      return currentTime
    },
    activityBtn (e, uid) {
      this.$router.push({
        name: 'cityQ5e',
        query: {
          id: e,
          uid: uid
        }
      })
    },
    cirBtn (e) {
      this.cirSel = e
      if (e === 1) {
        this.signLeft = '23.3%'
      } else if (e === 2) {
        this.signLeft = '48.3%'
      } else {
        this.signLeft = '73.3%'
      }
    },
    getElementTopLeft (obj) {
      var top = 0
      var left = 0
      while (obj) {
        top += obj.offsetTop
        left += obj.offsetLeft
        obj = obj.offsetParent
      }
      return {
        top: top,
        left: left
      }
    },
    pageScroll () {
      let mapObj = document.getElementById('card')
      if (this.getElementTopLeft(mapObj).top + mapObj.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(mapObj).top) {
        this.cardIcon = true
      } else {
        this.cardIcon = false
      }

      let mapObj2 = document.getElementsByClassName('adv-map')[0]
      if (this.getElementTopLeft(mapObj2).top + mapObj2.clientHeight > window.pageYOffset && window.pageYOffset + window.innerHeight > this.getElementTopLeft(mapObj2).top) {
        // console.log(1)
        this.mapIcon = true
      } else {
        // console.log(2)
        this.mapIcon = false
      }
    },
    changei (i) {
      this.findex = i
    },
    touchstart (e) {
      this.startX = e.targetTouches[0].pageX
      this.startY = e.targetTouches[0].pageY
    },
    touchmove (e) {
      var moveX = e.targetTouches[0].pageX
      var moveY = e.targetTouches[0].pageY
      if (Math.abs(moveX - this.startX) > Math.abs(moveY - this.startY)) {
        e.preventDefault()
      }
    }
  }
}
</script>

<style scoped lang="less">
.B1e-item {
  background: #fff;
}

.B1e-item .adv-map {
  position: relative;
  margin: 0 0.5rem;
}

.B1e-item .adv-map .map-tit1 {
  color: #464646;
  font-size: 0.6296rem;
  font-weight: bold;
  text-align: left;
  padding-bottom: .4rem;
}

.B1e-item .adv-map .map-tit2 {
  color: #464646;
  font-size: 0.2962rem;
  max-width: 100%;
  word-break: break-word;
  text-align: left;
  /* max-height: 3.4rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-bottom: .4rem;
}

.B1e-item .adv-map .map-icon img {
  position: absolute;
}

.B1e-item .adv-map .map-icon img:nth-child(1) {
  top: 51%;
  left: 50%;
  width: 9%;
}

.B1e-item .adv-map .map-icon img:nth-child(2) {
  top: 40%;
  left: 57%;
  width: 8.8%;
}

.B1e-item .adv-map .map-icon img:nth-child(3) {
  width: 8.5%;
  top: 30%;
  left: 63%;
}

.B1e-item .adv-map .map-icon img:nth-child(4) {
  width: 8.2%;
  top: 34%;
  left: 69%;
}

.B1e-item .adv-map .map-icon img:nth-child(5) {
  width: 7%;
  top: 22%;
  left: 71%;
}

.B1e-item .adv-map .map-icon img:nth-child(6) {
  width: 8.3%;
  top: 27%;
  left: 77%;
}

.B1e-item .adv-map .map-icon img:nth-child(7) {
  width: 8.5%;
  top: 30%;
  left: 84%;
}

.B1e-item .adv-map .map-icon img:nth-child(8) {
  width: 8.6%;
  top: 21%;
  left: 90%;
}

.B1e-item .adv-map .map-icon img:nth-child(9) {
  width: 3%;
  top: 62%;
  left: 65%;
}
.B1e-item .adv-map .map-icon.m2 img{
  &:nth-child(1){
    top: 59%;
    left: 47%;
    width: 7%;
  }
  &:nth-child(2){
    top: 56%;
    left: 55%;
    width: 7%;
  }
  &:nth-child(3){
    width: 7%;
    top: 54%;
    left: 64%;
  }
  &:nth-child(4){
    width: 7%;
    top: 42%;
    left: 60%;
  }
  &:nth-child(5){
    width: 6%;
    top: 29%;
    left: 57%;
  }
  &:nth-child(6){
    width: 6%;
    top: 11%;
    left: 56%;
  }
  &:nth-child(7){
    width: 7%;
    top: 31%;
    left: 66.5%;
  }
  &:nth-child(8){
    width: 7.6%;
    top: 41%;
    left: 74%;
  }
  &:nth-child(9){
    width: 6.6%;
    top: 39%;
    left: 83%;
  }
  &:nth-child(10){
    width: 5.6%;
    top: 31%;
    left: 89%;
  }
  &:nth-child(11){
    width: 8.6%;
    top: 26%;
    left: 76%;
  }
  &:nth-child(12){
    width: 6.6%;
    top: 14%;
    left: 87%;
  }
  &:nth-child(13){
    width: 7%;
    top: 13%;
    left: 80%;
  }
  &:nth-child(14){
    width: 6%;
    top: 1%;
    left: 75%;
  }
  &:nth-child(15){
    width: 6%;
    top: 1%;
    left: 85%;
  }
}
.B1e-item .adv-map .map-icon.m3 img{
  &:nth-child(1){
    top: 54%;
        left: 46%;
  }
  &:nth-child(2){
        top: 56.5%;
        left: 50.5%;
  }
  &:nth-child(3){
        top: 56.5%;
        left: 57.5%;
  }
  &:nth-child(4){
    top: 44%;
        left: 47.5%;
  }
  &:nth-child(5){
    width: 6%;
        top: 37.5%;
        left: 53.5%;
  }
  &:nth-child(6){
        top: 23%;
        left: 59%;
        width: 8%;
  }
  &:nth-child(7){
    top: 51%;
        left: 63.5%;
  }
  &:nth-child(8){
        width: 6%;
        top: 36%;
        left: 66.5%;
  }
  &:nth-child(9){
    top: 46.5%;
        left: 70%;
        width: 10%;
  }
  &:nth-child(10){
        top: 45.5%;
        left: 86%;
        width: 9%;
  }
  &:nth-child(11){
        top: 49.5%;
        left: 84%;
        width: 9%;
  }
  &:nth-child(12){
    top: 62%;
        left: 80%;
        width: 12%;
  }
}
.mapIcon1-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.1s;
  animation-fill-mode: both;
}
.mapIcon2-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.2s;
  animation-fill-mode: both;
}
.mapIcon3-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}
.mapIcon4-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.4s;
  animation-fill-mode: both;
}
.mapIcon5-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.mapIcon6-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.6s;
  animation-fill-mode: both;
}
.mapIcon7-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.7s;
  animation-fill-mode: both;
}
.mapIcon8-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}
.mapIcon9-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 1.9s;
  animation-fill-mode: both;
}
.mapIcon10-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.mapIcon11-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.1s;
  animation-fill-mode: both;
}
.mapIcon12-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.2s;
  animation-fill-mode: both;
}
.mapIcon13-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.3s;
  animation-fill-mode: both;
}
.mapIcon14-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.4s;
  animation-fill-mode: both;
}
.mapIcon15-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.5s;
  animation-fill-mode: both;
}
.mapIcon15-fade2-enter-active {
  animation: bounceInDown;
  animation-duration: 2.5s;
  animation-fill-mode: both;
}
.mapIcon1-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.1s;
  animation-fill-mode: both;
}
.mapIcon2-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.2s;
  animation-fill-mode: both;
}
.mapIcon3-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}
.mapIcon4-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.4s;
  animation-fill-mode: both;
}
.mapIcon5-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}
.mapIcon6-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.6s;
  animation-fill-mode: both;
}
.mapIcon7-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.7s;
  animation-fill-mode: both;
}
.mapIcon8-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}
.mapIcon9-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 1.9s;
  animation-fill-mode: both;
}
.mapIcon10-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 2s;
  animation-fill-mode: both;
}
.mapIcon11-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 2.1s;
  animation-fill-mode: both;
}
.mapIcon12-fade3-enter-active {
  animation: bounceInDown;
  animation-duration: 2.2s;
  animation-fill-mode: both;
}

.B1e-item .adv-map .cnts{
  position: absolute;
  width: 45%;
  top: 40%;
  transform: translateY(-50%);
  left: 0;
  padding-left: .4rem;
}
.B1e-item .adv-map .cnts .btns{
  display: flex;
  flex-wrap: wrap;
  a{
    border-radius: .1rem;
  }
}
.B1e-item .adv-map .adv-moreBtn {
  font-size: 0.2962rem;
  width: 80%;
  /* margin-right: 1%; */
  /* height: 8%; */
  margin-bottom: .2rem;
  padding: 0.1851rem 0.1851rem;
  background: #ffa200;
  color: #fff;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.B1e-item .adv-map .adv-moreBtn:hover {
  background: #ffc000;
}

.B1e-item .adv-map .adv-dwBtn {
  font-size: 0.2962rem;
  /* height: 8%; */
  width: 80%;
  /* margin-left: 1%; */
  padding: 0.1851rem 0.1851rem;
  background: rgba(255,255,255,.5);
  color: #000;
  border: 0.0185rem solid #000;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.B1e-item .cm .adv-map .adv-moreBtn,
.B1e-item .cm .adv-map .adv-dwBtn {
  border-radius: 0.1925rem;
  width: 25%;
}

.B1e-item .cm .adv-map .adv-moreBtn {
  top: 25%;
  left: 6%;
}

.B1e-item .cm .adv-map .adv-dwBtn {
  top: 36%;
  left: 6%;
}

.B1e-item .cm .adv-map {
  margin: 0 0.1rem;
}

.B1e-item .cm .map-tit1 {
  font-size: 0.5555rem;
  top: 16%;
  left: 6%;
}

.B1e-item .cm .map-tit2 {
  max-width: 100%;
  top: 48%;
  left: 3%;
  padding: 0 0.2rem;
}

.B1e-item .cm .adv-map .cirbg {
  bottom: 26.5%;
}

.B1e-item .cm .adv-map .circle {
  bottom: 25.5%;
}

.B1e-item .cm .adv-map .map-sign {
  bottom: 27%;
  transform: scale(0.8);
}

.B1e-item .adv-map .circle {
  position: absolute;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  background: #a4cd39;
  bottom: 13.5%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
}

.B1e-item .adv-map .cirbg {
  background: #05b6c8;
  width: 80%;
  height: 1%;
  position: absolute;
  bottom: 14.5%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1rem;
}

.B1e-item .adv-map .mapnames {
  position: absolute;
  bottom: 7%;
  width: 75%;
  left: 50%;
  transform: translateX(-50%);
}

.B1e-item .cm .adv-map .mapnames {
  bottom: 21%;
}

.B1e-item .adv-map .mapnames p {
  width: 33.33%;
  text-align: center;
  display: inline-block;
  font-size: 0.2062rem;
}

.B1e-item .adv-map .cir-active {
  background: #fdd000;
}

.mapIcon1-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.1s;
  animation-fill-mode: both;
}

.mapIcon2-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.2s;
  animation-fill-mode: both;
}

.mapIcon3-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.mapIcon4-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.4s;
  animation-fill-mode: both;
}

.mapIcon5-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

.mapIcon6-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.6s;
  animation-fill-mode: both;
}

.mapIcon7-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.7s;
  animation-fill-mode: both;
}

.mapIcon8-fade-enter-active {
  animation: bounceInDown;
  animation-duration: 1.8s;
  animation-fill-mode: both;
}

.mapIcon9-fade-enter-active {
  animation: zoomInUp;
  animation-duration: 1.9s;
  animation-fill-mode: both;
}

.B1e-item .adv-map .map-sign {
  width: 0.57rem;
  height: 0.72rem;
  position: absolute;
  bottom: 15%;
  left: 23.4%;
  background: url(../../assets/images/HOME/ico_map.png);
  background-size: 100% 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.B1e-item .top-bg {
  width: 100%;
  background: url(../../assets/images/B/B1/top-bg.png) no-repeat center top / cover;
  margin: 0 auto 4.074rem;
  border: 0.1px solid transparent;
}

.B1e-item .top-content {
  margin: 0.2rem 0 0 0;
  position: relative;
  transform: translateY(23.5%);
}

.B1e-item .top-content.cm {
}

.B1e-item .tit {
  margin: 0 auto 0;
}

.B1e-item .tit.t-one {
  width: 5.2592rem;
  height: 1.2592rem;
  background: url(../../assets/images/en/B1/t11.png) no-repeat center top / 100% 100%;
  margin-bottom: 1.0185rem;
}

.B1e-item .main-about {
  position: relative;
  width: 100%;
  height: 13.2777rem;
  background: url(../../assets/images/en/B1/about_bg.png) no-repeat center top / cover;
}

.B1e-item .main-about .link{
  position: absolute;
  width: 3rem;
  height: 4.5rem;
  bottom: 1%;
  right: 22%;
}

.B1e-item .main-about .about {
  position: relative;
  width: 22.2222rem;
  margin: 0 auto 0;
}

.B1e-item .main-about-left {
  width: 10.2777rem;
  padding: 0.3703rem;
  margin-top: 1rem;
  text-align: left;
  position: absolute;
}

.B1e-item .main-about-right {
  width: 10.2777rem;
  padding: 0.3703rem;
  margin-top: 1rem;
  text-align: left;
  position: absolute;
  left: 12.35rem;
  top: 0.5rem;
}

.B1e-item .icon1 {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.4444rem 0 0;
}

.B1e-item .left-p-one {
  display: inline-block;
  font-size: 0.4444rem;
  font-family: HYQiHei-DES;
  color: #00b6ce;
  vertical-align: middle;
}

.B1e-item .main-swiper {
  width: 10.2592rem;
}

.B1e-item .main-swiper .btns {
  width: 100%;
  text-align: right;
}

.B1e-item .main-swiper .btns .btn-left,
.B1e-item .main-swiper .btns .btn-right {
  width: 0.4629rem;
  height: 0.4629rem;
  display: inline-block;
  cursor: pointer;
}

.B1e-item .main-swiper .btns .btn-left {
  background: url(../../assets/images/B/B1/swiper-left.png) no-repeat center top/100% 100%;
  margin: 0 0.0185rem 0 0;
}

.B1e-item .main-swiper .btns .btn-right {
  background: url(../../assets/images/B/B1/swiper-right.png) no-repeat center top/100% 100%;
  margin: 0 0 0 0.0185rem;
}

.B1e-item .main-swiper .swiper {
  width: 10.2592rem;
  height: 5.7962rem;
  margin: 0.037rem 0 0.7407rem 0;
}

.B1e-item .main-swiper .swiper video {
  width: 100%;
  height: 100%;
}

.B1e-item .left-p-two {
  width: 9.1111rem;
  max-height: 2.1rem;
  overflow: hidden;
  font-size: 0.2962rem;
  font-family: HYQiHei-DES;
  font-weight: normal;
  color: #464646;
  line-height: 0.4444rem;
  margin: 0.2rem 0 0 4.1em;
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.B1e-item .main-about-right .left-p-two {
  width: 8.1111rem;
}

.B1e-item .main-about-right .main-p:nth-of-type(2) {
  margin: 1rem 0 0 0;
}

.B1e-item .main-mid-card {
  width: 100%;
  margin: 0 auto 0;
  background: url(../../assets/images/B/B1/main-mid-bg.png) no-repeat center bottom / 100% auto;
}

.B1e-item .swiper-item {
  background: url(../../assets/images/B/B1/main-mid-bg.png) no-repeat center bottom / 100% auto;
}

.B1e-item .main-mid-card .cardrow {
  max-width: 22.2222rem;
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;
}

.B1e-item .main-mid-card .mid-row2 {
  padding: 0 0 3rem 0;
}

.B1e-item .main-mid-card .el-row .el-col .card-cnt {
  border-radius: 0.1851rem;
  background: #ffa200;
  margin: 0 0.1851rem;
  overflow: hidden;
  height: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
}

.B1e-item .main-mid-card .el-row .el-col:nth-child(2) .card-cnt {
  background: #a4cd39;
}

.B1e-item .main-mid-card .el-row .el-col:last-child .card-cnt {
  background: #05cfdf;
  text-align: left;
}

.B1e-item .main-mid-card .el-row .el-col .card-cnt .mid-card {
  height: 1.2rem;
  font-size: 0.4444rem;
  font-family: HYQiHei-DES;
  font-weight: normal;
  color: #fff;
  margin: 0 auto 0;
  border-bottom: 1px solid #fff;
  line-height: 1.2rem;
  text-align: left;
  margin: 0 0.4rem;
}

.B1e-item .card-cnt .bom-icons-cnt {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0.3rem auto 0;
}

.B1e-item .card-cnt .bom-icons-cnt li {
  width: 33.33%;
}

.B1e-item .card-cnt .bom-icons-cnt li {
  width: 33.33%;
}

.B1e-item .main-mid-card .el-row .el-col:nth-child(2) .bom-icons-cnt li:nth-child(2) {
  width: 66.66%;
}

.B1e-item .main-mid-card .el-row .el-col:nth-child(3) li {
  width: 20%;
}

.B1e-item .card-cnt .bom-icons-cnt .card-icon {
  display: block;
  margin: 0 auto;
  width: 1.3703rem;
}

.B1e-item .main-mid-card .el-row .el-col:nth-child(2) li:nth-child(2) .card-icon {
  width: 2.7025rem;
}

.B1e-item .main-mid-card .el-row li:last-child .bom-icons-cnt .el-col {
  margin: 0 0.145rem;
}

.B1e-item .main-mid-card .card-p-cnt {
  font-family: HYQiHei-DES;
  font-weight: normal;
  color: #fff;
  font-size: 0.2562rem;
  word-break: break-word;
  padding: 0 10%;
  margin: 0.3rem auto 0;
}

.B1e-item .main-mid-card .main-left,
.B1e-item .main-mid-card .main-right {
  min-height: 500px;
  position: relative;
}

.B1e-item .main-mid-card .main-tit {
  display: block;
  margin: 1rem 0 0 0.2rem;
}

.B1e-item .main-mid-card .main-tit-m {
  display: none;
  margin: 1rem 0 0 0.2rem;
}

.B1e-item .main-left-swiper {
  margin: -0.4rem auto 0;
}

.B1e-item .main-right-swiper {
  margin: -0.4rem auto 0;
}

.B1e-item .swiper1 ul,
.B1e-item .swiper2 ul {
  margin: 1rem 0 0 0;
}

.B1e-item .swiper1 ul li {
  width: 8.9074rem;
  background: #f8f8f8;
  padding: 0.4rem 0;
  margin: 0.2rem 0 0 0.2rem;
  border-left: 2px solid #a6ce39;
}

.B1e-item .swiper2 ul li {
  width: 10.7074rem;
  background: #fff;
  padding: 0.4rem 0;
  margin: 0.2rem auto 0;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.B1e-item .swiper2 ul li .swipe2index {
  width: 1.2407rem;
  height: 1.2407rem;
  background: #a6ce39;
  border-radius: 50%;
  font-size: 0.8888rem;
  font-family: HYQiHeiY1-HEW;
  color: rgba(70, 70, 70, 1);
}

.B1e-item .swiper2 ul li .swipe2index p {
  font-weight: bolder;
  transform: rotateX(-40deg);
  line-height: 0.45rem;
}

.B1e-item .swiper1 ul li:nth-child(2n) {
  border-left: 2px solid #1dbbd3;
}

.B1e-item .swiper2 ul li:nth-child(2n) .swipe2index {
  background: #0fb7d1;
}

.B1e-item .swiper2 ul li:first-child {
  border-top: 1px solid #e2e2e2;
}

.B1e-item .swiper1 ul li .swiper1-cnt {
  font-size: 0.4444rem;
  color: #464646;
  padding: 0 0.84rem;
  text-align: left;
  position: relative;
}

.B1e-item .swiper1 ul li .swiper1-cnt::before,
.B1e-item .swiper1 ul li .swiper1-cnt::after {
  content: '';
  position: absolute;
  width: 0.4259rem;
  height: 0.3148rem;
  background: url(../../assets/images/B/B1/y.png) no-repeat center top /100% 100%;
}

.B1e-item .swiper1 ul li .swiper1-cnt::before {
  top: -0.1rem;
  left: 0.3rem;
}

.B1e-item .swiper1 ul li .swiper1-cnt::after {
  transform: rotate(180deg);
  bottom: 0.2rem;
  right: 0.5rem;
}

.B1e-item .swiper1bom {
  display: flex;
  margin: 0.6rem 0 0 0;
}

.B1e-item .swiper1tx {
  width: 1.1481rem;
  width: 1.1481rem;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  margin: 0 0 0 0.8rem;
}

.B1e-item .swiper1cnt2 {
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: left;
  margin: 0 0 0 0.3rem;
}

.B1e-item .swiper1-name {
  color: #464646;
  font-size: 0.2962rem;
}

.B1e-item .swiper1-date {
  color: #b1b1b1;
  font-size: 0.2592rem;
}

.B1e-item .swiper2 .swiper2cnt1 {
  width: 88%;
  text-align: left;
  padding: 0 0 0 0.3rem;
}

.B1e-item .swiper2 .swiper2-tit {
  color: #464646;
  font-size: 0.4444rem;
  font-weight: bold;
  padding: 0.15rem 0 0.2rem 0;
}

.B1e-item .swiper2 .swiper2-cnt {
  color: #7d7d7d;
  font-size: 0.2962rem;
  padding: 0 0 0.15rem 0;
}

.B1e-item .mian-mid2 {
  background: #0fb7d1;
  padding: 2rem 0;
  position: relative;
}

.B1e-item .t-fonr {
  width: 7.1851rem;
  height: 1.3518rem;
  display: block;
  margin: 0 auto 0;
}

.B1e-item .t-fonr-m {
  width: 7.1851rem;
  height: 1.3518rem;
  display: none;
  margin: 0 auto 0;
}

.B1e-item .t-five {
  width: 6.1111rem;
  height: 1.1111rem;
  display: block;
  margin: 2rem auto 1.5rem;
}

.B1e-item .t-five-m {
  width: 5.2777rem;
  height: 1.3148rem;
  display: none;
  margin: 1rem auto 1rem;
}

.B1e-item .more-btn {
  width: 3.1296rem;
  height: 0.7962rem;
  border: 1px solid #fff;
  font-size: 0.2962rem;
  text-align: center;
  line-height: 0.7962rem;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: -1.4rem;
  right: 0rem;
  transition: all 0.2s;
}

.B1e-item .more-btn:hover {
  background: #fff;
  color: #0fb7d1;
  transform: scale(1.1);
}

.B1e-item .main-mid2-showpic {
  max-width: 22.2222rem;
  margin: 2.5rem auto 0;
  position: relative;
}

.B1e-item .main-mid2-showpic.s2 {
  margin: 0 auto 0;
}

.B1e-item .main-mid2-showpic .el-col {
  height: 5.5185rem;
  position: relative;
  overflow: hidden;
}

.B1e-item .main-mid2-showpic .el-col img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  cursor: pointer;
  transition: all 0.2s;
}

.B1e-item .main-mid2-showpic .el-col .first-show {
  background: #fff;
  width: 100%;
  height: 100%;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt {
  width: 70%;
  text-align: left;
  margin-top: -0.3rem;
}

.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt .c1 {
  color: #393939;
  font-size: 0.2962rem;
  font-weight: bold;
}

.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt .c2 {
  color: #a8a8a8;
  font-size: 0.6666rem;
  font-weight: bold;
}

.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt .c3 {
  color: #a8a8a8;
  font-size: 0.2962rem;
}

.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt .c1 p,
.B1e-item .main-mid2-showpic .el-col .first-show .first-cnt .c2 p {
  font-weight: bold;
}

.B1e-item .main-mid2-showpic .el-col img:hover,
.B1e-item .main-mid2-showpic .el-col video:hover {
  transform: scale(1.1);
}

.B1e-item .main-mid2-showpic .el-col .mid2-tit {
  position: absolute;
  display: inline-block;
  bottom: 0.6rem;
  left: 15%;
  color: #0fb7d1;
  font-size: 0.2962rem;
  vertical-align: middle;
}
.B1e-item .main-mid2-showpic .el-col .mid2-tit2 {
  position: absolute;
  display: inline-block;
  bottom: 0.6rem;
  left: 15%;
  color: #0fb7d1;
  font-size: 0.2962rem;
  vertical-align: middle;
}

.B1e-item .main-mid2-showpic .mid2-tit::before,
.B1e-item .main-mid2-showpic .el-col .first-show + p.mid2-tit::before {
  content: '';
  display: inline-block;
  width: 0.2962rem;
  height: 0.2777rem;
  background: url(../../assets/images/B/B1/play-icon.png) no-repeat center top/100% 100%;
  vertical-align: middle;
  margin: 0 0.2rem 0 0;
  position: relative;
  top: -0.03rem;
}
.B1e-item .main-mid2-showpic  .mid2-tit2::before {
  content: '';
  display: inline-block;
  width: 0.2962rem;
  height: 0.2777rem;
  background: url(../../assets/images/B/B1/pic-icon.png) no-repeat center top/100% 100%;
  vertical-align: middle;
  margin: 0 0.2rem 0 0;
  position: relative;
  top: -0.03rem;
}
.B1e-item .main-mid2-showpic .el-col .p-side {
  font-size: 0.2592rem;
  color: #fff;
  position: absolute;
  display: inline-block;
  bottom: 0.25rem;
  left: 24%;
  vertical-align: middle;
}
@media screen and (max-width: 1300px) {
  .B1e-item .adv-map .map-tit1{
    font-size: 0.4596rem;
    padding-bottom: .2rem;
  }
  .B1e-item .adv-map .map-tit2 {
    -webkit-line-clamp: 4;
    margin-bottom: .2rem;
  }
  .B1e-item .adv-map .btns .adv-moreBtn,.B1e-item .adv-map .btns .adv-dwBtn{
    width: 80%;
  }
}
@media screen and (max-width: 990px) {
  .B1e-item .main-about .link{
    right: 6%;
  }
  .B1e-item .adv-map .cnts{
    width: 100%;
    padding-top: .2rem;
    padding-left: .2rem;
    top: 32%;
  }
  .B1e-item .adv-map .cnts .fra{
    display: flex;
    flex-direction: column-reverse;
  }
  .B1e-item .adv-map .cnts .map-tit1{
    padding-bottom: .2rem;
    font-size: 0.4596rem;
    padding-left: .4rem;
    padding-top: 1.4rem;
    width: 50%;
  }
  .B1e-item .adv-map .cnts .map-tit2{
    margin-bottom: .2rem;
    padding-left: .3rem;
    padding-right: .3rem;
    width: 50%;
  }
  .B1e-item .adv-map .btns{
    width: 50%;
    padding-left: .4rem;
  }
  .B1e-item .adv-map .btns .adv-moreBtn,.B1e-item .adv-map .btns .adv-dwBtn{
    width: 60%;
  }
  .B1e-item .left-p-two {
    font-size: 0.3703rem;
    max-height: 2.1rem;
  }

  .B1e-item .main-about {
    width: 100%;
    height: 33.7777rem;
    background: url(../../assets/images/en/B1/sz_about_bg.jpg) no-repeat center top/100% auto;
  }

  .B1e-item .main-about .about {
    width: 100%;
  }

  .B1e-item .main-about-left {
    margin-top: 0.7rem;
    padding-right: 0;
    width: 10rem;
  }

  .B1e-item .tit.t-one {
    margin-bottom: 0.3703rem;
    width: 5.2777rem;
    height: 1.2407rem;
    background: url(../../assets/images/en/B1/t11.png) no-repeat center top / 100% 100%;
  }

  .B1e-item .main-swiper {
    width: 8.2592rem;
  }

  .B1e-item .main-swiper .swiper {
    width: 12.2592rem;
    height: 6.9481rem;
    margin: 0.337rem 0 1.0407rem 0;
  }

  .B1e-item .main-about-left .icon1:nth-of-type(2) {
    margin: 2.2rem 0.4444rem 0 1.4rem;
  }

  .B1e-item .main-about-left .left-p-one:nth-of-type(2) {
    margin-top: 2.2rem;
  }

  .B1e-item .main-about-left .left-p-two {
    margin-left: 2.4rem;
    width: 9.8511rem;
  }

  .B1e-item .main-about-right {
    left: 0;
    top: 17.2rem;
    padding-right: 0;
    width: 10rem;
  }

  .B1e-item .main-about-right .main-p:nth-of-type(1) {
    margin-top: 0.3rem;
  }

  .B1e-item .main-about-right .left-p-two {
    width: 9.8511rem;
    margin-left: 1.45rem;
  }

  .B1e-item .main-about-right .main-p:nth-of-type(2) {
    margin: 4.5rem 0 0 1.4rem;
  }

  .B1e-item .main-mid-card .cardrow {
    width: 100%;
  }

  /* .B1e-item .card-cnt .bom-icons-cnt .el-col .card-icon{
			transform: scale(.8);
		} */
  .B1e-item .main-mid-card .el-row .el-col:nth-child(1) .card-cnt {
    margin-right: 0;
    border-radius: 0.1851rem 0 0 0.1851rem;
  }

  .B1e-item .main-mid-card .el-row .el-col:nth-child(2) .card-cnt {
    margin-left: 0;
    border-radius: 0 0.1851rem 0.1851rem 0;
  }

  .B1e-item .main-mid-card .el-row .el-col:nth-child(3) .card-cnt {
    margin-top: 0.26rem;
  }

  .B1e-item .more-btn {
    left: 50%;
    margin-left: -1.5648rem;
    right: auto;
  }

  .B1e-item .main-mid2-showpic {
    width: 100%;
    padding: 0 0.2rem;
  }

  .B1e-item .t-fonr,
  .B1e-item .t-five,
  .B1e-item .main-mid-card .main-tit {
    display: none;
  }

  .B1e-item .t-fonr-m,
  .B1e-item .t-five-m,
  .B1e-item .main-mid-card .main-tit-m {
    display: block;
  }

  .B1e-item .main-mid-card .main-tit-m {
    width: 8rem;
  }

  .B1e-item .swiper1 ul li {
    width: auto;
    margin: 0.2rem 0.2rem 0 0.2rem;
  }

  .B1e-item .swiper2 ul li {
    width: auto;
    margin: 0.2rem 0.2rem 0 0.2rem;
  }

  .B1e-item .swiper2 .swiper2cnt1 {
    width: 85%;
  }

  .B1e-item .main-mid2-showpic .el-col {
    height: 4.8255rem;
  }

  .B1e-item .main-mid2-showpic .el-col .mid2-tit {
    left: 8%;
  }

  .B1e-item .main-mid2-showpic.s2 .el-col:nth-child(1) .mid2-tit {
    left: 4%;
  }

  .B1e-item .main-mid2-showpic .el-col .p-side {
    left: 16%;
  }

  .B1e-item .main-mid2-showpic .el-col:nth-child(1) .p-side {
    left: 8%;
  }

  .B1e-item .main-mid2-showpic .el-col .first-show + p.mid2-tit {
    left: 16%;
  }

  .B1e-item .main-mid-card .mid-row2 {
    padding-bottom: 1rem;
  }

  .main-funActive {
    padding: 0 0.4rem 1rem 0.4rem;
  }

  .B1e-item .main-mid-card .item-fun {
    padding-bottom: 0;
  }

  .B1e-item .top-content {
    /* margin: 2.2rem 0 0 0; */
  }

  .B1e-item .top-bg {
    /* height: 11.7777rem; */
  }
  .B1e-item .top-content:not(.cm) .adv-map .map-tit1 {
    top: 8%;
  }
  .B1e-item .top-content:not(.cm) .adv-map .map-tit2 {
    top: 20%;
  }
}

@media screen and (max-width: 760px) {
  .B1e-item .main-about .link{
    right: 0%;
  }
  .B1e-item .adv-map .cnts{
    top: 0;
    transform: translateY(0);
  }
  .B1e-item .adv-map .cnts .map-tit2{
    width: 100%;
  }
  .B1e-item .adv-map .mapnames p{
    font-size: 0.1333rem;
    line-height: 1;
  }
  .B1e-item .cm .adv-map .mapnames{
    bottom: 20%;
  }
  .left-p-two {
    max-height: 2.7rem !important;
    font-size: 0.2962rem !important;
  }

  .B1e-item .main-about {
    width: 100%;
    height: 24.7777rem;
    background: url(../../assets/images/en/B1/sz_about_bg.jpg) no-repeat center top/100% auto;
  }

  .B1e-item .main-about .about {
    width: 100%;
  }

  .B1e-item .main-about-left {
    margin-top: 0.5rem;
    padding-right: 0;
    width: 10rem;
  }

  .B1e-item .tit.t-one {
    margin-bottom: 0.3703rem;
    width: 5.2777rem;
    height: 1.2407rem;
    background: url(../../assets/images/en/B1/t11.png) no-repeat center top / 100% 100%;
  }

  .B1e-item .main-swiper {
    width: 8.2592rem;
  }

  .B1e-item .main-swiper .swiper {
    width: 8.2592rem;
    height: 4.6481rem;
    margin: 0.337rem 0 1.0407rem 0;
  }

  .B1e-item .main-about-left .icon1:nth-of-type(2) {
    margin: 0.2rem 0.4444rem 0 1rem;
  }

  .B1e-item .main-about-left .left-p-one:nth-of-type(2) {
    margin-top: 0.2rem;
  }

  .B1e-item .main-about-left .left-p-two {
    margin-left: 2.4rem;
    width: 6.8511rem;
  }

  .B1e-item .main-about-right {
    left: 0;
    top: 11rem;
    padding-right: 0;
    width: 10rem;
  }

  .B1e-item .main-about-right .main-p:nth-of-type(1) {
    margin-top: 0.3rem;
  }

  .B1e-item .main-about-right .left-p-two {
    width: 6.8511rem;
    margin-left: 1.45rem;
  }

  .B1e-item .main-about-right .main-p:nth-of-type(2) {
    margin: 1.4rem 0 0 1rem;
  }

  .B1e-item .main-mid-card .cardrow {
    width: 100%;
  }

  /* .B1e-item .card-cnt .bom-icons-cnt .el-col .card-icon{
			transform: scale(.8);
		} */
  .B1e-item .main-mid-card .el-row .el-col:nth-child(1) .card-cnt {
    margin-right: 0;
    border-radius: 0.1851rem 0 0 0.1851rem;
  }

  .B1e-item .main-mid-card .el-row .el-col:nth-child(2) .card-cnt {
    margin-left: 0;
    border-radius: 0 0.1851rem 0.1851rem 0;
  }

  .B1e-item .main-mid-card .el-row .el-col:nth-child(3) .card-cnt {
    margin-top: 0.26rem;
  }

  .B1e-item .more-btn {
    left: 50%;
    margin-left: -1.5648rem;
    right: auto;
  }

  .B1e-item .main-mid2-showpic {
    width: 100%;
    padding: 0 0.2rem;
  }

  .B1e-item .t-fonr,
  .B1e-item .t-five,
  .B1e-item .main-mid-card .main-tit {
    display: none;
  }

  .B1e-item .t-fonr-m,
  .B1e-item .t-five-m,
  .B1e-item .main-mid-card .main-tit-m {
    display: block;
  }

  .B1e-item .main-mid-card .main-tit-m {
    width: 8rem;
  }

  .B1e-item .swiper1 ul li {
    width: auto;
    margin: 0.2rem 0.2rem 0 0.2rem;
  }

  .B1e-item .swiper2 ul li {
    width: auto;
    margin: 0.2rem 0.2rem 0 0.2rem;
  }

  .B1e-item .swiper2 .swiper2cnt1 {
    width: 85%;
  }

  .B1e-item .main-mid2-showpic .el-col {
    height: 4.8255rem;
  }
  .B1e-item .over-hidden {
    display: grid;
  }
  .B1e-item .over-hidden .item-img {
    border-radius: unset;
  }
  .B1e-item .buzz-funActive .display-none {
    display: none !important;
  }

  .main-funActive {
    padding: 0 0.4rem 1rem 0.4rem;
  }

  .B1e-item .sm-up-noshow button {
    width: 50%;
    padding: 0.2rem 0;
    background: none;
    color: #464646;
    font-size: 0.35rem;
    border: 1px solid #bfda5d;
    cursor: pointer;
  }

  .B1e-item .fun-btn.sm-up-noshow {
    display: block;
  }

  .title-funactive {
    display: none;
  }

  .B1e-item .top-content {
    /* margin: 2.2rem 0 0 0; */
  }

  .B1e-item .top-bg {
    margin: 0 auto 2.074rem;
  }

  .B1e-item .funActive-card .fcard-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.3rem;
    .over-hidden{
      display: grid;
      img{
        border-radius: unset;
        height: 2.5rem;
      }
    }
    .fcard-content{
      .fcard-icon{
        margin-top: 0.5rem;
      }
      .fcard-foot{
        padding: 0;
        border-top: none;
        img{
          position: absolute;
          right: 6%;
          bottom: 5%;
        }
      }
    }
  }

  .B1e-item .funActive-card .fcard-icon {
    margin-top: 0.5rem;
  }

  .B1e-item .main-mid2-showpic.s2 .el-col:nth-child(1) .mid2-tit {
    left: 5%;
  }

  .B1e-item .main-mid2-showpic .el-col .p-side {
    left: 20%;
  }

  .B1e-item .main-mid2-showpic .el-col:nth-child(1) .p-side {
    left: 10.5%;
  }

  .B1e-item .main-mid2-showpic .el-col .mid2-tit {
    left: 9%;
  }
}

.B1e-item .item-fun {
  width: 100%;
  padding-top: 0.2rem;
  background: #f0f0f0;
  padding-bottom: 2rem;
}

.main-funActive {
  max-width: 1200px;
  margin: 0 auto;
}

.B1e-item .buzz-funActive {
  padding-bottom: 0.5rem;
}

.B1e-item .funActive-title {
  position: relative;
  text-align: left;
}

.B1e-item .funActive-title .fun-btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.B1e-item .fun-btn button {
  color: #464646;
  font-size: 0.25rem;
  padding: 0.15rem 0.5rem;
  background: #f0f0f0;
  border: 1px solid #bfda5d;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  outline: none;
}

.B1e-item .fun-btn button:hover {
  color: #fff;
  background: #bfda5d;
}

.B1e-item .funActive-card {
  margin-top: 0.5rem;
  margin-right: -0.35rem;
  .small{
    display: inline-block;
    width: 100%;
  }
}

.B1e-item .fcard-item {
  position: relative;
  display: inline-block;
  background: #fff;
  margin-right: 0.3rem;
  border: 1px solid #D5D5D5;
  width: 95%;
}

.B1e-item .over-hidden {
  overflow: hidden;
}

.B1e-item .item-img {
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
  display: block;
  height: 3.7rem;
}

.B1e-item .item-img:hover {
  transform: scale(1.1);
}

.B1e-item .fcard-content {
  padding: 0 0.4rem;
  text-align: left;
}

.B1e-item .fcard-txt {
  position: relative;
  color: #474747;
  font-size: 0.3rem;
  margin-left: 0.15rem;
  height: 1rem;
  display: flex;
  align-items: center;
}

.B1e-item .fcard-txt::before {
  content: ' ';
  position: absolute;
  top: 10%;
  left: 0;
  height: 80%;
  border-right: 3px solid #a6ce36;
}

.B1e-item .fcard-txt p {
  padding-left: 0.4rem;
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.B1e-item .fcard-icon {
  color: #d3d3d3;
  font-size: 0.25rem;
  margin-top: 0.2rem;
  margin-bottom: 0.1rem;
  p{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    i{
      color: #BFDA5D;
      font-size: 0.3rem;
      margin-right: 0.3rem;
    }
  }
}

.B1e-item .fcard-foot {
  display: flex;
  align-items: center;
  padding: 0.14rem 0;
  border-top: 1px solid #d3d3d3;
}

.B1e-item .fcard-foot img {
  float: left;
}

.B1e-item .fcard-foot span {
  margin-left: 0.3rem;
}

.B1e-item .sm-up-noshow {
  display: none;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 0.5s;
}

.slide-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-enter,
.slide-leave-to {
  transform: translateY(20%);
  opacity: 0;
}
</style>
<style lang="stylus">
  .B1e-item{
    .video-js .vjs-big-play-button{
      top 50%
      left 50%
      transform translate(-50%,-50%)
    }
    .main-mid-card{
      .popup-box{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 555;
        background: rgba(0,0,0,0.8);
        .play-box{
          position relative
          img{
            width: 10rem;
          }
          video{
            width 13rem
          }
          .close-btn{
            display: block;
            text-align: right;
            font-size: .5rem;
            cursor: pointer;
            z-index: 666;
            color: #0fb7d1;
            margin-right: -0.5rem;
          }
        }
      }
    }
    .main-swiper .swiper{
      video {
        width: 100%;
        height: 100%;
      }
      p{
        display initial
        .video-js{
          width 100%
          height 100%
        }
      }
    }
    .mid-row2{
      button.el-carousel__arrow{
        transform: translate(0,0);
        top: 0;
        border-radius: 0;
        width: 0.4629rem;
        height: 0.4629rem;
      }
      .el-icon-arrow-left,.el-icon-arrow-right{
        display: none;
      }
      .el-carousel__arrow--left{
        background url('../../assets/images/B/B1/swiper-left.png') no-repeat center top / 100% 100%
      }
      .el-carousel__arrow--right{
        background url('../../assets/images/B/B1/swiper-right.png') no-repeat center top / 100% 100%
        right 2rem
      }
      .el-carousel__arrow--left{
        background: url('../../assets/images/B/B1/swiper-left.png') no-repeat center top / 100% 100%
        right: 2.54rem
        left: auto
      }
      .main-right-swiper{
        .el-carousel__arrow--right{
          background: url('../../assets/images/B/B1/swiper-right.png') no-repeat center top / 100% 100%
          right: .2rem
        }
        .el-carousel__arrow--left{
          background: url('../../assets/images/B/B1/swiper-left.png') no-repeat center top / 100% 100%
          right: 0.74rem
          left: auto
        }
      }
    }
  }
  @media screen and (max-width: 760px) {
    .B1-item .main-mid-card .popup-box .play-box{
      img {
        width 8.5rem
      }
      video{
        width 8.5rem
      }
    }
    .B1e-item{
      .mid-row2{
        .el-carousel__arrow--right{
          right .25rem
        }
        .el-carousel__arrow--left{
          right: 0.84rem
          left: auto
        }
        .main-right-swiper{
          .el-carousel__arrow--right{
            right .25rem
          }
          .el-carousel__arrow--left{
            right .84rem
            left auto
          }
        }
      }
    }
  }
</style>
